module.exports = {
  intro: {
    title1: "Introducing Pay with Points",
    title2: "by AYANA Rewards",
    subtitle:
      "Turn your AYANA Rewards Points into Unforgettable Experiences at AYANA Hotels",
    cta: "CHECK RATES",
  },
  title: "Put your way to AYANA Rewards Points at Riverside Golf Club",
  pros: {
    first: {
      title: "Earn Points & Unlock Rewards with Every Round",
      description:
        "Starting May 2024, earn 1 AYANA Rewards point for every IDR 15,000 spent on green fees at Riverside Golf Club.        ",
    },
    second: {
      title: "Unlock AYANA Rewards Exclusive Benefits",
      description:
        "As an AYANA Rewards member, your points open doors to exciting benefits*, such as tee time discounts, hotel room upgrades, early check-in, late check-out, and more.",
      tierLevel: "*Based on your tier level.",
    },
  },
  preferred_dest: {
    title: "Redeem your points and choose your AYANA Escape",
    subtitle:
      "Unforgettable experiences await at AYANA Hotels across Indonesia! Redeem your AYANA Rewards points for a luxurious escape or treat yourself to a taste of paradise.",
    bali: "Bali",
    jakarta: "Greater Jakarta",
    komodo: "Komodo",
    DXKR: "Stay at Delonix Hotel",
    JKTHB: "Stay at AYANA Jakarta",
    SEGARA: "AYANA Segara Bali",
    hotelStay: "Hotel Stay",
    giftVoucher: "Gift Voucher",
    krisFlyerMiles: "KrisFlyer Miles",
    diningVoucher: "Dining Voucher",
  },
  how_it_works: {
    title:
      "Unlock Exclusive Benefits & Rewards: AYANA Rewards at Riverside Golf Club",
    subtitle: "Here's how it works:",
    first: "Elevate your experience with member-only perks. Join now!",
    second:
      "Instantly elevate to AYANA Rewards Gold level upon purchasing an Annual Riverside membership. (T&C applied)",
    third:
      "Instantly elevate to AYANA Rewards Platinum level upon purchasing a Long Term Riverside membership (T&C applied)",
    fourth:
      "Gold and Platinum member can earn bonus points based on the eligible spending (T&C applied)",
  },
  find_hotels: {
    title: "Become an AYANA Rewards Member and Enjoy Exclusive Privileges.",
    cta: "SIGN UP NOW!",
  },
  faq: {
    title: "Frequently Asked Questions",
    first: {
      question: "How can I book a tee time at Riverside Golf Club?",
      answer:
        "You can book your tee time online through the Riverside Golf Club website:",
      link: "https://riverside-gc.com/",
    },
    second: {
      question:
        "Do I get AYANA Rewards points when I purchase an annual or long-term Riverside membership?",
      answer:
        "No, you won't earn AYANA Rewards points directly from purchasing an annual or long-term Riverside membership. However, these memberships offer instant upgrades to your AYANA Rewards tier.",
    },
    third: {
      question:
        "How long does it take for my points to be issued to my account?",
      answer:
        "It usually takes up to 7 working days for your AYANA Rewards points to be issued to your account. If you have any questions about missing points after 7 working days, you can contact AYANA Rewards directly at info@ayanarewards.com.",
    },
  },
  tnc: "Terms & Conditions",
};
